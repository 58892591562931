.outer-container-model-success-upload-review {
  width: 400px;
  background: #f4e3eb;
  max-width: 80%;
  border-radius: 10px;
  padding: 5px 0;
  position: relative;
}
.successMessageBackground {
  background: #4caf50;
}
.errorMessageBackground {
  color: #d9534f; /* Soft red color */
  font-weight: bold;
}

.btn-danger-close {
  font-size: 25px !important;
  position: absolute;
  top: 0px !important;
  right: 10px;
  color: #111;
  font-size: 20px;
  font-weight: 800;
  cursor: pointer;
}

.inner-container-model-success-upload-review {
  width: 95%;
  display: flex;
  margin: 0 auto;
  padding-top: 10px;
}
.model-error-check-circle {
  width: 100%;
  font-size: 25px;
  margin: 0 !important;
  color: #111;
}
.inner-container-model-success-upload-review div h3 {
  font-size: 20px;
  margin: 0 !important;
  color: #111;
  font-weight: 900;
  margin-bottom: 5px !important;
}
.inner-container-model-success-upload-review div span {
  font-size: 18px;
  margin: 0 !important;
  color: #111;
  font-weight: 600;

  margin-bottom: 5px !important;
}
.inner-container-model-success-upload-review div p {
  font-size: 15px;
  margin: 0 !important;
  color: #111;
  margin: 0 !important;
}
