/* Title */
.section-title {
  text-align: center;
  margin-bottom: 1.4rem;
}
.section-title h1 {
  font-size: 1.2rem;
  letter-spacing: var(--mainSpacing);
  text-transform: capitalize;
  text-decoration: underline;

  //margin-bottom: 1rem;
  /* color: black !important; */
}
// .section-title div {
//   width: 5rem;
//   height: 5px;
//   margin: 0 auto;
//   background: var(--primaryColor);
// }
/* end of Title */
