/*end global container-post job */

/* start the slider job */
.section-jobs-description {
  width: 90vw;
  max-width: 1171px;

  margin: 0 auto;
}
.section-jobs-description p {
  line-height: 28px;
  font-weight: 600;
}
.company-logo-articles {
  /* background: #fff !important;
                */
  box-shadow: 0 0 60px 0 rgba(0, 0, 0, 0.1);
  width: 95vw;
  max-width: 95vw !important;
  overflow: hidden;
  z-index: 1;
  height: 200px;
  margin: 1rem auto 0 !important;
}

.container-company-logo-article {
  position: relative;
  margin: 0rem auto !important;
  height: 100%;
}
.company-logo-articles-side {
  position: absolute;
  top: 0;
  width: 40px;
  overflow: hidden;
  height: fit-content;
  z-index: 1;
}
.company-logo-articles-side1 {
  left: 0;
  background-image: linear-gradient(90deg, #fff 90%, hsla(0, 0%, 100%, 0));
}
.company-logo-articles-side2 {
  right: 0;
  background-image: linear-gradient(270deg, #fff 90%, hsla(0, 0%, 100%, 0));
}
.company-logo-articles-container {
  width: 1614px !important;
  position: relative;
  top: 0;
  left: 0;
  overflow: visible;
  height: 100% !important;
  margin: 0 auto !important;
}
.company-logo-articles-container-logo {
  display: grid;
  grid-template-columns: repeat(7, 200px);
  grid-column-gap: 32px;
  margin: 0 auto;
  margin-top: 2rem;
  position: absolute;
}
@keyframes slide1 {
  0% {
    left: 0;
  }
  100% {
    left: -100%;
  }
}
.company-logo-articles-container-logo1-companies {
  animation: slide1 50s linear 0s infinite;
  top: 0;
}
@keyframes slide2 {
  0% {
    left: 100%;
  }
  100% {
    left: 0;
  }
}
.company-logo-articles-container-logo2-companies {
  top: 0;
  animation: slide2 50s linear 0s infinite;
}

.company-logo-articles-container-logo div {
  width: 200px;
  height: 150px;
}
.company-logo-articles-container-logo div img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  pointer-events: none;
  opacity: 1;
}
.opacity-companies {
  opacity: 1 !important ;
  -webkit-filter: grayscale(100%) brightness(100%) !important;
  filter: grayscale(100%) brightness(59%) !important;
}

/*end of the silder job */
