/* start of the single blog style */
.big-container-social-share {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  // margin-top: 1rem;
}
.big-container-social-share p {
  font-weight: 800;
}
.container-social-share {
  width: 200px;
  display: flex;
  justify-content: space-between;
  text-align: center;
  align-items: center;
  margin-left: 4%;
}

.container-social-share div {
  width: 30px;
  height: 30px;
}
.container-social-share div img {
  width: 100%;
  height: 100%;
  border-radius: 4px;
  object-fit: cover;
}
.container-social-share button {
  background: transparent !important;
  border: none !important;
  cursor: pointer;
  translate: var(--mainTransition);
}
.container-social-share button:hover {
  transform: translateY(-4px);
}
@media only screen and (max-width: 1025px) {
  /* start of the single blog style */
  // .big-container-social-share {
  //   display: flex;
  //   justify-content: flex-start;
  //   align-items: center;
  //   // margin-top: 1rem;
  // }
  .big-container-social-share p {
    font-weight: 800;
  }
  .container-social-share {
    width: 300px;
    // display: flex;
    // justify-content: space-between;
    // text-align: center;
    // align-items: center;
    // margin-left: 4%;
  }

  .container-social-share div {
    width: 40px;
    height: 40px;
  }
}

@media only screen and (max-width: 821px) {
  .container-social-share {
    width: 350px;
  }

  .container-social-share div {
    width: 40px;
    height: 40px;
  }
}

@media only screen and (max-width: 415px) {
  .container-social-share {
    width: 350px;
  }
}

@media only screen and (max-width: 361px) {
  .container-social-share {
    width: 300px !important;
  }
  .container-social-share div {
    width: 30px !important ;
    height: 30px !important;
  }
}
