/* start style for authentification and dark mode */

// .header-authentification-dark-mode {
//   display: flex;
//   justify-content: space-between;
//   align-items: center;
// }
.header-authentification-dark-mode_dark-mode {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.header-authentification-dark-mode_dark-mode span:nth-child(2) {
  margin: 0 3px;
}
.header-authentification-dark-mode_login {
  padding: 8px 16px;
  background-color: #059862 !important;
  border-radius: 25px;
  color: #fff;
  font-size: 16px;
  text-transform: capitalize;
  cursor: pointer;
}
.header-authentification-dark-mode_logout {
  display: flex;

  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}
.header-authentification-dark-mode_logout_container-logo-image {
  width: 35px !important;
  height: 35px !important;
}
.header-authentification-dark-mode_logout_container-logo-image img {
  width: 100%;
  height: 100%;
  border-radius: 100%;
}
.header-authentification-dark-mode_logout_container-name-icon {
  display: flex;
  justify-content: space-between;
  align-items: center;

  margin-left: 7px;
}

.header-authentification-dark-mode_logout_container-name-icon span {
  transition: var(--mainTransition);
  color: #fff;
  color: #111;
  font-size: 13px;
  font-weight: 400;
}
.container-logo-image-name-icon_icon {
  transition: var(--mainTransition);
}
.header-authentification-dark-mode_logout_container-name-icon .icon {
  color: #059862;
  font-size: 25px;
}

.header-authentification-dark-mode_logout_container-name-icon span:hover,
.header-authentification-dark-mode_logout_container-name-icon .icon:hover {
  color: var(--primaryColor);
}
.toggleLeft {
  left: 3px;
}
.toggleRight {
  right: 3px;
}
