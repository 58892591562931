.single-container-review {
  max-width: 95%;
  width: 450px;
  height: 520px;
  border-radius: 10px;
  // background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
  //   url("../../assets/img/tech_companies.jpeg");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  place-items: center;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  cursor: pointer;
}
.single-container-review:hover {
  background: rgba(0, 0, 0, 0.8);
  background: linear-gradient(rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.8));

  transition: var(--mainTransition);
}

.single-container-review:hover .single-container-review_description_majors {
  visibility: visible;
  transition: var(--mainTransition);
}
// .single-container-review:nth-child(1)
//   .single-container-review_description_majors {
//   visibility: visible;
//   transition: var(--mainTransition);
// }
// .single-container-review:nth-child(1)
//   .single-container-review__major_title:nth-child(1) {
//   visibility: hidden;
// }
.single-container-review__major_title {
  color: #fff;
  font-weight: 900;
  width: 170px;
  width: fit-content;
  font-size: 30px !important;
  text-align: center;
}
.single-container-review:hover .single-container-review__major_title {
  visibility: hidden;
}
// .single-container-review_type {
//   position: absolute;
//   background: #fff;
//   top: 0%;
//   left: 0%;
//   transform: translate(20%, -50%);
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   padding: 3px;
// }
.single-container-review_type span {
  color: #111;
  font-weight: 800;
  font-size: 10px !important;
}
.single-container-review_btnHide {
  position: absolute;
  top: 3%;
  right: 3%;
}
.single-container-review_btnHide svg {
  color: #fff !important;
}
.single-container-review_description_majors {
  // width: 420px;
  // height: 400px;
  width: 100%;
  height: 100%;
  position: absolute;
  display: flex;
  // flex-direction: column !important;
  justify-content: space-between !important;
  align-items: center;
  top: 0%;
  left: 0%;
  border-radius: 10px;
  visibility: hidden;
}

.single-container-review_description_majors__inner {
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 10px 0;
}

.single_book_first_row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 95%;
  margin: 0 auto;
}
.single_book_first_row_first_item {
  display: flex;
  justify-content: space-between;
  align-items: center;

  width: fit-content;
  margin-right: 2px;
}
.single_book_first_row_first_item p {
  color: #fff;
  font-weight: 800;
}
.single_book_first_row_second_item {
  background: grey !important;
  // max-width: 90%;
  width: 35%;
  height: 15px;
  border-radius: 5px;
}

.single_book_first_row_third_item span {
  color: #fff;
}

@media screen and (max-width: 1281px) {
  .single-container-review {
    width: 420px;
    height: 560px;
    margin: 0 auto;
  }

  .single-container-review__major_title {
    width: fit-content;
    font-size: 30px;
  }
}
@media screen and (max-width: 1025px) {
  .single-container-review__major_title {
    width: fit-content;
    font-size: 50px !important;
  }
}
@media screen and (max-width: 854px) {
  .single-container-review {
    height: 580px !important;
  }
}
@media screen and (max-width: 821px) {
  .single-container-review_description_majors__inner {
    padding: 0 !important;
  }
  .single-container-review {
    //max-width: 95%;
    width: 700px;
    height: 630px !important;
  }
  .single_book_first_row_first_item p {
    font-size: 1.2rem;
  }
  .single_book_first_row_third_item span {
    font-size: 1.2rem;
  }
}
@media screen and (max-width: 769px) {
  .single-container-review {
    max-width: 95%;
    width: 500px;
    height: 700px !important;
  }
}

@media screen and (max-width: 541px) {
  .single-container-review {
    width: 100%;
    height: 550px !important;
    margin: 0 auto;
  }
}

@media screen and (max-width: 431px) {
  .single-container-review {
    max-width: 98%;
    // width: 400px;
    height: 450px;
  }

  .single_book_first_row_first_item p {
    font-size: 1rem;
  }
  .single_book_first_row_third_item span {
    font-size: 0.9rem;
  }
}
@media screen and (max-width: 415px) {
  .single-container-review {
    max-width: 98%;
    // width: 400px;
    height: 520px !important;
  }

  // .single_book_first_row_first_item p {
  //   font-size: 1rem;
  // }
  // .single_book_first_row_third_item span {
  //   font-size: 0.9rem;
  // }
}
@media screen and (max-width: 390px) {
  .single-container-review {
    max-width: 98%;
    width: 360px;
    height: 450px;
  }
}
@media screen and (max-width: 376px) {
  .single-container-review {
    max-width: 98%;
    width: 360px;
    height: 510px !important;
  }
}
@media screen and (max-width: 361px) {
  .single-container-review {
    max-width: 98%;
    width: 355px;
  }
}
@media screen and (max-width: 345px) {
  .single-container-review {
    max-width: 98%;
    // width: 318px;
    height: 560px !important;
  }
}
@media screen and (max-width: 321px) {
  .single-container-review {
    height: 540px !important;
  }
}
