.section-center {
  max-width: 100%;
  width: 1400px;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(450px, 1fr));
  grid-row-gap: 2rem !important;
  grid-column-gap: 1rem !important;
  margin: 0 auto !important;
  text-align: center !important;
  padding: 0 !important;
  justify-content: center;
  align-content: center;
  justify-items: center;
}

@media screen and (max-width: 1281px) {
  .section-center {
    grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
  }
}

@media screen and (max-width: 321px) {
  .section-center {
    grid-template-columns: repeat(auto-fill, minmax(318px, 1fr));
  }
}
