@import "./Normalize.css";
@import "./Reset.css";
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  scroll-behavior: smooth;
}
/* code snippet code */
.code-block {
  position: relative;
  margin: 20px;
}

.copy-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: #007bff;
  color: white;
  border: none;
  padding: 5px 10px;
  cursor: pointer;
  font-size: 14px;
  border-radius: 3px;
}
/*code snippet code */
.body-navbar {
  margin-top: 100px;
  width: 100vw;
}

textarea {
  white-space: pre-line !important ;
}
.errorReview {
  color: red !important;
  font-size: 15px !important;
  margin: 0 auto;
  text-align: center;
  margin-left: 10% !important;
  padding: 0 !important;
  margin: 0 !important;
}
.successReview {
  color: green !important;
  font-size: 15px !important;
  margin: 0.5rem auto;
  text-align: center;
  text-align: left;

  padding: 0 !important;
}

:root {
  /* dark shades of primary color*/
  /* --clr-primary-1: hsl(43, 86%, 17%); */
  --clr-primary-1: hsl(184, 91%, 17%);
  --clr-primary-2: hsl(185, 84%, 25%);
  --clr-primary-3: hsl(185, 81%, 29%);
  --clr-primary-4: hsl(184, 77%, 34%);
  --clr-primary-5: #2caeba;
  /* lighter shades of primary color */
  --clr-primary-6: hsl(185, 57%, 50%);
  --clr-primary-7: hsl(184, 65%, 59%);
  --clr-primary-8: hsl(184, 80%, 74%);
  --clr-primary-9: hsl(185, 94%, 87%);
  --clr-primary-10: hsl(186, 100%, 94%);
  /* darkest grey - used for headings */
  --clr-grey-1: hsl(209, 61%, 16%);
  --clr-grey-3: hsl(209, 34%, 30%);
  --clr-grey-4: hsl(209, 28%, 39%);
  /* grey used for paragraphs */
  --clr-grey-5: hsl(210, 22%, 49%);
  --clr-grey-8: hsl(210, 31%, 80%);
  --clr-grey-9: hsl(212, 33%, 89%);
  --clr-grey-10: hsl(210, 36%, 96%);
  --clr-green-dark: hsl(125, 67%, 35%);
  --clr-red-dark: hsl(360, 67%, 44%);
  --clr-red-light: hsl(360, 71%, 66%);
  --clr-white: #fff;
  --ff-primary: "Roboto", sans-serif;
  --ff-secondary: "Open Sans", sans-serif;
  --transition: all 0.5s linear;
  --spacing: 0.2rem;
  --radius: 0.25rem;
  --light-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  --dark-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
  --max-width: 1170px;
  --fixed-width: 700px;
}

:root {
  --primaryColor: #af9a7d;
  /* --primaryColor:#0e2439; */
  --secondPrimaryColor: #0af;
  /* --primaryColor:blue; */
  /* --primaryColor: #f69314; */
  --mainWhite: #fff;
  --offWhite: #f7f7f7;
  --mainBlack: #222;
  --mainGrey: #ececec;
  --darkGrey: #cfcfcf;
  --mainTransition: all 0.5s ease-in-out;
  --clr-grey-10: hsl(210, 36%, 96%);
  --mainSpacing: 3px;
  --lightShadow: 2px 5px 3px 0px rgba(0, 0, 0, 0.5);
  --clr-grey-5: hsl(210, 22%, 49%);
  --clr-primary-5: #2caeba;
  --clr-grey-9: hsl(212, 33%, 89%);
  --dark-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
  --darkShadow: 4px 10px 5px 0px rgba(0, 0, 0, 0.5);
  --radius: 0.25rem;
  --dark-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
}

/* start of the global style */
body {
  /* padding-top: 100px; */
  color: var(--mainWhite);
  background-color: #f6f9fc;
  background-color: #0e2439;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  line-height: 1.4;
  scroll-behavior: smooth;
  cursor: vertical-text;
}

.scroll {
  overflow: hidden;
}
h1 {
  font-size: 2em;
  line-height: 1;
  margin-bottom: 0.5em;
  /* color: #0b1332;
  font-family: "Qanelas Soft Bold" !important; */
}
h2 {
  font-size: 2em;
  margin-bottom: 0.75;
  /* text-align: left; */
}
.opacity {
  opacity: 0.2;
}
h3 {
  font-size: 1.5em;
  line-height: 1;
  margin-bottom: 1em;
}
h4 {
  font-size: 1.2rem;
  line-height: 1.25;
  margin-bottom: 1.25;
  color: #111;
  color: #fff;
  text-align: center;
  flex: 0, 0, 10px;
}
h5 {
  font-size: 1em;
  font-weight: bold;

  /* margin-bottom: 1.5em; */
}
h6 {
  font-size: 1em;
  font-weight: bold;
  /* margin-bottom: 1.5em; */
}
.btn-primary {
  display: inline-block;
  text-decoration: none;
  letter-spacing: var(--mainSpacing);
  color: var(--mainBlack);
  background: var(--primaryColor);
  padding: 0.4rem 0.9rem;
  border: 3px solid var(--primaryColor);
  transition: var(--mainTransition);
  text-transform: uppercase;
  cursor: pointer;
  border-radius: var(--radius);
  margin: 2rem auto;
  text-align: center;
}
.tutorial,
.blog,
.project {
  display: block;
  margin-bottom: 2rem;
  background: var(--clr-grey-10);
  border-radius: var(--radius);
  box-shadow: var(--light-shadow);
  transition: var(--mainTransition);
}

/* .loading {
  text-transform: capitalize;
  text-align: center;
  margin-top: 3rem;
} */

.btn-primary:hover {
  background: transparent;
  color: var(--primaryColor);
}
.section-title {
  text-align: center;
  margin-bottom: 1rem;
  /* padding-top: 100px; */
}
.section-title h4 {
  font-size: 2rem;
  letter-spacing: var(--mainSpacing);
  text-transform: capitalize;
  margin-bottom: 1rem;
  color: #111;
  color: #fff;
}
.section-title div {
  width: 5rem;
  height: 5px;
  margin: 0 auto;
  background-color: var(--primaryColor);
}
a {
  text-decoration: none;
  color: var(--primaryColor);

  /* color: #4799eb; */
}

/* end of the global style */

/* start of the navbar style */

/* .container-navBar {
  width: 100vw;
} */
.active-link {
  font-weight: 900 !important;
}

.navbar {
  width: 100vw;
  width: 100%;
  position: fixed;
  height: 80px;
  margin: 0;
  padding: 0;
  top: 0;
  left: 0;
  align-items: center;
  background: linear-gradient(-90deg, #02203c, #001528);
  background: transparent;
  display: none;
  z-index: 80;
  transition: var(--mainTransition);
  /* position: relative; */
}

.active-navbar {
  background: linear-gradient(-90deg, #02203c, #001528);
  display: flex;
  transition: var(--mainTransition);
}
.nav-center {
  width: 90%;
  margin: 0 auto;
  display: flex;
  height: fit-content;
  justify-content: space-between;

  align-items: center;
}

.nav-btn {
  background: transparent;
  border: none;
  cursor: pointer;
  outline: none;
}
.nav-icon {
  display: block;
  font-size: 2rem;
  color: var(--mainWhite);
  height: auto-fit;
}
.nav-icon-logo {
  font-size: 2rem;
  color: var(--mainWhite);
  transition-duration: 1.5s;
  cursor: pointer;
}
.nav-icon-logo:hover {
  transform: rotate(360deg);
  transition-duration: 1.5s;
}

.nav-icon-login {
  font-size: 0.9rem;
  text-transform: capitalize;
}
.nav-icon-login-desktop {
  display: block;
  color: var(--mainWhite);
  height: auto-fit;
  font-size: 0.9rem;
  border: none;
  background: transparent;
  cursor: pointer;
  outline: none;
  text-transform: capitalize;
}
/* start of logo-container style */
.container-logo {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
  opacity: 1;
  transition: var(--mainTransition);
  width: fit-content;
}
.container-logo:hover {
  opacity: 0.5;
}
.container-logo-symbol {
  background-color: #fff;
  padding: 1.5%;
}
.container-logo-symbol span {
  color: #111;
  font-weight: 600;
}
.container-logo-title {
  margin-left: 5%;
  /* display: none; */
}
@media only screen and (max-width: 281px) {
  .nav-center {
    width: 95%;
    margin: 0 auto;
  }
  .container-logo-title {
    display: none;
  }
}
.container-logo-title span {
  font-size: 1rem;
  font-family: Jura, Helvetica, Arial, sans-serif;
  color: #fff;
  font-weight: 700;
}
/* end of logo-container style */
/* start of the link style */
.container-nav-links {
  display: none;
}
.nav-links {
  display: flex;
  justify-content: space-between;
  align-items: center;
  display: none;
}
.nav-links li {
  list-style-type: none;
}

.nav-links li a {
  display: block;
  width: fit-content;
  text-decoration: none;
  color: var(--mainWhite);
  text-align: center;
  font-size: 14px;
  text-transform: capitalize;
  font-weight: 500;
}

.nav-links li:nth-child(n)::after {
  content: "";
  display: block;
  border-bottom: 1px solid var(--mainWhite);
  width: 0%;
  transition: var(--mainTransition);
}
.nav-links li:nth-child(n):hover::after {
  width: 100%;
}
/* end of the logo container sytle */

/* start of the container-login-signup */
.nav-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: fit-content;
}
.container-login-signup {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: fit-content;
}
.container-login-signup button {
  font-size: 16px;
  padding: 5%;
  transition: var(--mainTransition);
}
.container-login-signup button:hover {
  font-size: 15px;
  opacity: 0.7;
}
.container-login-signup button:nth-child(1) {
  color: #fff;
  font-size: 22px;
  transition: var(--mainTransition);
}
/* .container-login-signup button:nth-child(2) {
  background: var(--primaryColor);
  color: black;
  margin-left: 10%;
} */
/* @media only screen and (max-width: 280px) {
  .nav-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .container-login-signup {
    display: flex;
    justify-content: space-between;
    align-items: center;
    
  }
  .container-login-signup button {
    font-size: 16px;
    padding: 5%;
    transition: var(--mainTransition);
  }
  .container-login-signup button:hover {
    font-size: 15px;
    opacity: 0.7;
  }
  .container-login-signup button:nth-child(1) {
    background: white;

    color: black;
  }
  .container-login-signup button:nth-child(2) {
    background: var(--primaryColor);
    color: black;
   
  }
} */
/* end of the container-login-signup */

.sociauxLink-top {
  display: flex;
  justify-content: center;
  align-items: center;
  align-items: center;
  height: 100%;
}

.container-sociaux-links {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 300px;
  height: auto;
  margin: 1.5rem 0;
}
.container-image-link {
  position: relative;
  overflow: hidden;
  display: inline-block;
  width: 27px;
  height: 27px;
  translate: var(--mainTransition);
}
.container-image-link:hover {
  transform: translateY(-3px);
}

.container-image-link img {
  position: absolute;
  top: 0;
  left: 0;
  object-fit: cover;
  object-position: center center;
  transition: none 0s ease 0s;
  opacity: 1;
  width: 100%;
  height: 100%;
  border-radius: 4px;
}
@keyframes slideUp {
  0% {
    transform: translateY(200px);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}
/* .container-sociaux-links a {
  opacity: 0;
  animation: slideUp 0.5s ease-in-out 0.3s forwards;
}
.container-sociaux-links a:nth-of-type(1) {
  animation-delay: 0.25s;
}
.container-sociaux-links a:nth-of-type(2) {
  animation-delay: 0.5s;
}
.container-sociaux-links a:nth-of-type(3) {
  animation-delay: 0.75s;
}
.container-sociaux-links a:nth-of-type(4) {
  animation-delay: 1s;
}
.container-sociaux-links a:nth-of-type(5) {
  animation-delay: 1.25s;
}
.container-sociaux-links a:nth-of-type(6) {
  animation-delay: 1.5s;
}
.container-sociaux-links a:nth-of-type(7) {
  animation-delay: 1.75s;
} */
@media screen and (min-width: 1000px) {
  .nav-center {
    width: 95%;
    margin: 0 auto;
  }

  .nav-btn {
    display: none;
  }
  .container-nav-links {
    width: 50%;
  }
  .nav-links {
    display: flex;
    width: 100%;
    justify-content: space-between;
    justify-content: flex-start;
    align-items: center;
    position: relative;
  }
  .nav-links li:nth-child(n + 2) {
    margin-left: 3%;
  }
  .navContent-menu {
    width: 100%;
    height: 170px;
    background: #fff;
    position: absolute;
    top: 200%;
    right: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    opacity: 0;
    pointer-events: none;
    /* transform: translateX(-10px); */
    transition: var(--mainTransition);
    box-shadow: 0 2px 15px 0.3 rgb(0, 0, 0, 0.1);
  }
  .active-dropdown {
    pointer-events: auto;
    opacity: 1;
    /* transform: translateY(0); */
  }
  .navContent-menu-child {
    width: 90%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    height: 80%;
  }
  .navContent-menu-child ul {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }

  .navContent-menu-child ul li a span {
    color: black;
    padding-bottom: 6px;
    width: 100%;
    border-bottom: 1px solid #111;
    cursor: pointer;
    transition: var(--mainTransition);
  }
  .navContent-menu-child ul li a span:hover {
    color: var(--primaryColor);
    font-weight: 800;
    border-bottom: 1px solid var(--primaryColor);
  }
  /* .service-icon-navbar-menu {
    font-size: 150%;
  } */

  .container-sociaux-links {
    visibility: visible;
  }
  .container-logo-title {
    display: block;
  }
  .container-nav-links {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}
@media only screen and (max-width: 1281px) {
  .navContent-menu-child ul li a span {
    font-size: 11.5px;
  }
}
@media only screen and (max-width: 1025px) {
  /* .container-nav-links  */
  .nav-links li:nth-child(7) {
    display: none !important;
  }
  .navContent-menu-child ul li a span {
    font-size: 9px;
  }
}
/* end of the link style */

/* end of the navbar style */
/* start of the hero style */

/* .defaultHero {
  min-height: calc(100vh - 100px);
  background: url("./assets/hero1.jpg");
  background: url("./assets/video/background-video.mp4");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  place-items: center;
  display: flex;
  justify-content: center;
  align-items: center;
} */
.defaultHero {
  min-height: calc(100vh - 100px);
  background: linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),
    url("./assets/hero1.jpg");
  /* background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url("./assets/video/background-video.mp4"); */
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  place-items: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.defaultReview {
  min-height: calc(60vh - 100px);
  /* background: linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),
    url("./assets/hero1.jpg"); */
  /* background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url("./assets/video/background-video.mp4"); */
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  place-items: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* end of the hero style */
/* start of the banner style*/
.banner {
  display: block;
  background: rgba(0, 0, 0, 0.5);
  color: var(--mainWhite);
  /* padding: 2rem 1rem; */
  /* margin:2rem 1rem; */
  text-align: center;
  text-transform: capitalize;
  letter-spacing: var(--mainSpacing);
  /* width: 100vw; */
}
.banner h1 {
  font-size: 1.3rem;
  line-height: 2rem;
  font-weight: 800;
  z-index: 1000;
}
.banner div {
  width: 10rem;
  height: 8px;
  background: var(--primaryColor);
  /* margin: 1.7rem auto; */
}
.banner p {
  font-size: 0.8rem;
  margin-bottom: 2rem;
  text-shadow: 2px 2px 8px #111;
  /*== font-weight: 550; */
  text-align: center;
  line-height: 2;
}
@media screen and (min-width: 280px) {
  .banner h1 {
    font-size: 1rem;
    line-height: 2rem;
  }
  .banner p {
    font-size: 0.75rem;
    margin-bottom: 2rem;
    text-shadow: 2px 2px 8px #111;
    /*== font-weight: 550; */
    text-align: center;
    /* line-height: 0.5px; */
  }
}
@media screen and (min-width: 576px) {
  .banner {
    padding: 2rem 3rem;
  }
  .banner h1 {
    font-size: 2rem;
    text-shadow: 2px 2px 8px #111;
    line-height: 3rem;
  }
  .banner p {
    font-size: 1.2rem;
    line-height: 2rem;
  }
}
@media screen and (min-width: 992px) {
  .banner {
    padding: 2rem 6rem;
  }
  .banner h1 {
    font-size: 3rem;
    line-height: 5rem;
  }
}
@media screen and (min-width: 1024px) {
  .banner h1 {
    font-size: 1.5rem;
    line-height: 3rem;
  }
  .banner p {
    font-size: 1rem;
    margin-bottom: 2rem;
  }
}
@media screen and (min-width: 1280px) {
  .banner h1 {
    font-size: 3rem;
    line-height: 5rem;
  }
  .banner p {
    font-size: 1.2rem;
    line-height: 2rem;
  }
}
/* end of the banner style */

/* start of the blog style */

.section {
  padding: 1rem 0;
  text-align: center;
  /* position: relative; */
  /* width: 100%; */
}

/* start of the testimonials style */
.container-affiliates-reviews {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  width: 100vw;
  max-width: 1171px;
  margin: 0 auto;
}
@media screen and (max-width: 300px) {
  .container-affiliates-reviews {
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    width: 90vw;
  }
}

.section-center-home {
  width: 90vw;
  grid-template-columns: repeat(12, 367px);
  margin-top: 2rem;
  grid-column-gap: 32px;
  transition: transform 400ms cubic-bezier(0.165, 0.84, 0.44, 1) 0s;
}

.container-testimonial {
  background: #fff;
  padding: 1rem;
  border-radius: var(--radius);
  box-shadow: 0 2px 10px rgba(50, 50, 93, 0.1);
}
@media screen and (max-width: 300px) {
  .section-center-home {
    grid-template-columns: repeat(12, 270px);
  }
  .container-testimonial {
    width: 100%;
  }
  .testimonial-article {
    width: 270px;
  }
}

.testimonial-name-image {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 80%;
  margin: 0 auto;
}
.container-title-company-description {
  width: 90%;
  display: flex;
  flex-direction: column;
  text-align: left;
  margin: 0.1rem auto;
}
.testimonial-name-image img {
  width: 70px;
  height: 70px;
  border-radius: 100%;
  box-shadow: var(--lightShadow);
}
.testimonial-name-image h4 {
  text-transform: capitalize !important;
  margin-left: 0.6rem;

  font-family: "Ubuntu", "Helvetica", "Arial", sans-serif;
  text-transform: none;
  font-weight: 600;
  color: #02203c;
  text-transform: capitalize;
  font-size: 18px;
}
.container-title-company-description h4 {
  margin: 0.2rem 0;
  text-transform: capitalize;
  font-size: 18px;
  font-family: "Ubuntu", "Helvetica", "Arial", sans-serif;
  text-transform: none;
  font-weight: 400;
  color: #02203c;
}
.container-title-company-description h4:nth-child(1) {
  padding-top: 0.8rem;
  font-size: 18px;
  font-weight: 600;
  text-transform: capitalize;
}
.container-company-logo {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0.4rem 0;
}
.company-logo {
  width: 72px;
  height: 30px;
}
.container-slide-btn {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 85px;
  margin-top: 1rem;
  margin: 1rem auto;
  color: var(--primaryColor);
}
.container-slide {
  display: flex;
  font-size: 1.2rem;
  justify-content: center;
  align-items: center;
  background: #fff;
  box-shadow: 0 2px 4px 1px rgba(50, 50, 93, 0.1);
  padding: 0.6rem;
  border-radius: 100%;
  cursor: pointer;
  transition: transform 0.3s, box-shadow 0.2s;
}
.container-slide:hover {
  transform: scale(1.02);
  transform: translateY(-3px);
}
/* end of the testimonials style */

/* start of the companies style*/
.section-companies {
  background: #fff;
  box-shadow: 0 0 60px 0 rgba(0, 0, 0, 0.1);
  /* width: 100%; */
  max-width: 1171px;
  /* max-width: 100vw; */
  overflow: hidden;
  z-index: 1;
  margin: 0 auto;
  padding: 2rem 0;
}
.companies-article {
  position: relative;
}
.companies-article .companies-container-side {
  position: absolute;
  top: 0;
  width: 40px;
  overflow: hidden;
  height: 100%;
  z-index: 1;
}
.companies-article .companies-container-side1 {
  left: 0;
  background-image: linear-gradient(90deg, #fff 90%, hsla(0, 0%, 100%, 0));
}
.companies-article .companies-container-side2 {
  right: 0;
  background-image: linear-gradient(270deg, #fff 90%, hsla(0, 0%, 100%, 0));
}
.companies-article-container {
  width: 1614px;
  height: 100%;
  margin: 0 auto;
  position: relative;
}
.companies-container {
  position: relative;
  height: 430px;

  flex: 0, 0, 250px;
}
.container-logo-company {
  display: grid;
  grid-template-columns: repeat(7, 200px);
  grid-column-gap: 32px;
  margin: 0 auto;
  margin-top: 2rem;
  position: absolute;
}
@keyframes slide1 {
  0% {
    left: 0;
  }
  100% {
    left: -100%;
  }
}
.container-logo1-companies {
  animation: slide1 20s linear 0s infinite;
  top: 0;
}
@keyframes slide2 {
  0% {
    left: 100%;
  }
  100% {
    left: 0;
  }
}
.container-logo2-companies {
  top: 0;
  animation: slide2 20s linear 0s infinite;
}
@keyframes slide3 {
  0% {
    left: -100%;
  }
  100% {
    left: 0;
  }
}
.container-logo3-companies {
  bottom: 0;
  animation: slide3 20s linear 0s infinite;
}
@keyframes slide4 {
  0% {
    left: 0%;
  }
  100% {
    left: 100%;
  }
}
.container-logo4-companies {
  bottom: 0;
  animation: slide4 20s linear 0s infinite;
}
/* .companies-article .companies-container-side {
  position: absolute;
  top: 0;
  width: 40px;
  overflow: hidden;
  height: 100%;
  z-index: 1;
}
.companies-article .companies-container-side1 {
  left: 0;
  background-image: linear-gradient(90deg, #fff 90%, hsla(0, 0%, 100%, 0));
}
.companies-article .companies-container-side2 {
  right: 0;
  background-image: linear-gradient(270deg, #fff 90%, hsla(0, 0%, 100%, 0));
} */
/* .companies-container div {
  width: 200px;
  height: 150px;
}
.companies-container div img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  pointer-events: none;
  opacity: 1;
}
.opacity-companies {
  opacity: 1 !important ;
  -webkit-filter: grayscale(100%) brightness(100%) !important;
  filter: grayscale(100%) brightness(59%) !important;
} */
/* end of the companies style */

/* start of the affiliate style */
/* .container-description {
  max-width: 800px;
  width: 90%;
  margin: 2rem auto;
  margin-bottom: 0;
  padding: 0;
}
.container-description p {
  text-align: left;

  font-size: 1rem;
  color: #111;
  font-weight: 400;
  line-height: 1.5;
  letter-spacing: 1.4px;
  margin-bottom: 1.5rem;
}
.container-description p span {
  font-weight: 600;
  font-size: 15px;
} */
/* end of the affiliate style */

/* start of the footer style */

/* .footer {
  width: 100vw;
  min-height: 100vh;
  background: #000000;
  background: rgba(0, 0, 0, 0.5);
  padding: 2rem 0;
}
.container-new-letter {
  width: 100vw;
  margin: 2rem 0;
}
.container-new-letter p:nth-child(1) {
  font-size: 1.2rem;
  color: #fff;
}
.container-new-letter p:nth-child(2) {
  font-size: 1rem;
  color: #fff;
}
.new-letters {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
}
.new-letters input {
  width: 30%;
  height: 60px;
  border: none;
  padding-left: 5%;
  outline: none;
}
.new-letters input::placeholder {
  text-align: 6%;
}
.new-letters button {
  width: 5%;
  height: 60px;
  cursor: pointer;
  background: #fff;
  color: black;
  border: none;
  font-weight: bold;
  margin-left: 0.2%;
  transition: var(--mainTransition);
}
.new-letters button:hover {
  background: var(--primaryColor);
  color: white;
}
.container-new-letter .successReview {
  margin-bottom: 1rem;
  font-size: 1.2rem !important;
}
.container-new-letter .errorReview {
  margin-top: 1rem;
  font-size: 1.2rem !important;
}

@media screen and (max-width: 761px) {
  .new-letters {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
  }
  .new-letters input {
    width: 75%;
    border: none;
    outline: none;
  }
  .new-letters button {
    width: 20%;
    font-size: 14px;
  }
  .new-letters input::placeholder {
    text-align: 0;
    font-size: 14px;
  }
}

.footer-product-links p a {
  color: #fff;
  font-size: 1rem !important;
  transition: var(--mainTransition);
}
.footer-product-links p a:hover {
  color: var(--primaryColor);
}

.container-footer {
  display: grid;
  grid-template-columns: 1fr;
  width: 90vw;
  text-align: left;
  margin: 0 auto;
}
.container-footer div h4 {
  color: var(--primaryColor);
  text-align: left;
  margin: 1em 0;
  font-size: 1.2rem;
  text-transform: capitalize;
}
/* .container-footer div ul {

} */
.container-footer div ul li {
  list-style: none;
  line-height: 1.7;
  font-weight: 600;
}
.container-footer div ul li a {
  text-decoration: none;
  font-size: 12px;
  color: #fff;
  text-transform: capitalize;
}
address {
  display: block;
  font-size: 14px;
}
.office-address {
  font-size: 14px;
  color: var(--primaryColor);
}
.email-address {
  font-size: 14px;
  font-weight: italic;
  color: white;
  text-transform: none;
}

footer {
  position: relative;
}
.copyright {
  position: absolute;
  bottom: 0;
  color: #fff;
  font-size: 13px;
  text-align: center;
  left: 50%;
  transform: translateX(-50%);
  letter-spacing: var(--mainSpacing);
  /* width: 100vw; */
}
.copyright span {
  color: var(--primaryColor);
}

@media screen and (min-width: 700px) {
  .container-footer {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(fit-content, 1fr));
    margin: 0 auto;
    width: 90vw;
  }
  .container-footer div h4 {
    font-size: 1.2rem;
  }
  .container-footer div ul li a {
    font-size: 13px;
  }
  .footer-product-links p a {
    font-size: 13px;
    font-weight: 600;
  }
}
@media screen and (min-width: 1000px) {
  .container-footer {
    display: flex;
    justify-content: space-between;
    margin: 0 auto;
    width: 95vw;
  }
}
/* 
/* end of the footer style */
/* start of the slider style */
.slider {
  position: fixed;
  top: 0;
  min-height: 100vh;
  width: 100vw;
  left: 0;
  opacity: 0;
  transform: translateX(-100%);
  z-index: 99;
  background-color: #111;
  padding-top: 5%;
  transition: var(--mainTransition);
  margin: 0 auto;
}
.showSlider {
  opacity: 0.97;
  transform: translateX(0);
}
.btn-slide-right {
  color: #fff;
  padding-left: 80%;
  font-size: 30px;
}
.slider-container {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  margin-top: 4%;
  width: 100vw;
}
.container-all-content {
  margin: 0% 0 1%;
  width: 95vw;
  height: 100vh;
}
.container-all-content ul {
  width: 100vw;
  height: 100vh;
}
.container-all-content li {
  list-style: none;
}
.container-all-content li a {
  display: grid;
  align-items: center;
  margin-top: 5%;
  grid-template-columns: auto 1fr;
}
.container-all-content li a span {
  width: 100%;
  margin-left: 20%;
  font-size: 1rem;
  color: var(--primaryColor);
}
@keyframes slideRight {
  0% {
    transform: translateX(-200px);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}
.container-all-content li {
  opacity: 0;
  animation: slideRight 0.5s ease-in-out 0.3s forwards;
}
.container-all-content li:nth-of-type(1) {
  animation-delay: 0.25s;
}

.container-all-content li:nth-of-type(2) {
  animation-delay: 0.5s;
}
.container-all-content li:nth-of-type(3) {
  animation-delay: 0.75s;
}
.container-all-content li:nth-of-type(4) {
  animation-delay: 1s;
}
.container-all-content li:nth-of-type(5) {
  animation-delay: 1.25s;
}
.container-all-content li:nth-of-type(6) {
  animation-delay: 1.5s;
}
.container-all-content li:nth-of-type(7) {
  animation-delay: 1.75s;
}
.container-all-content li:nth-of-type(8) {
  animation-delay: 2s;
}
.container-all-content li:nth-of-type(9) {
  animation-delay: 2.25s;
}
.container-all-content li:nth-of-type(10) {
  animation-delay: 2.5s;
}
.container-all-content li:nth-of-type(11) {
  animation-delay: 2.75s;
}

.active-btn {
  border-left: 7px solid var(--primaryColor);
}
.slider-container .nav-header .container-logo .container-logo-symbol span {
  font-size: 1.2rem;
}
.slider-container .nav-header .container-logo .container-logo-title span {
  font-size: 1.2rem;
}
@media screen and (min-width: 279px) {
  .container-all-content li {
    margin-top: 7%;
  }
}

/* end of the contents style */

/*start of the subtitle style project */
/* .section-subtitle-project {
  background: #fff;
  min-height: 100vh;
}
.all-container-subtitle-project {
  width: 95%;
  margin: 3rem auto;
  border: 1px solid #e0e7ed;
  border-radius: var(--radius);
}
.container-subtitle-project {
  border-top: 1px solid #e0e7ed;
  padding: 2rem 0;
  width: 100%;
}

.container-subtitle-project
  .all-container-subtitle-project
  .container-subtitle-project:nth-child(2) {
  padding-top: 0px;
  border-top: 0px;
}
.all-container-subtitle-project div:nth-child(2) {
  border-top: none;
  padding-top: 0;
}

.container-subititle-project__duration {
  display: flex;
  justify-content: center;
  align-items: center;
}
.container-subititle-project__duration span {
  color: black;
  margin: 1rem 0;
  margin-left: 1rem;
}
.all-container-subtitle-project .view-video {
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--secondPrimaryColor);
  padding: 0.5rem;
  border-radius: var(--radius);
  color: white;
  width: 80%;
  margin: 0 auto;
  text-transform: capitalize;
}
.all-container-subtitle-project__title {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1.5rem;
  margin-bottom: 1.5rem;
  background-color: #f7f8fc;
  border-color: #e0e7ed;
  border-bottom: 1px solid #e0e7ed;
  color: #36434d;
  text-transform: capitalize;
}
@media screen and (min-width: 992px) {
  .container-contents-sectionProject {
    display: grid;
    grid-template-columns: 20% 80%;
  }
  .all-container-subtitle-project {
    width: 70%;
  }
  .all-container-subtitle-project .view-video {
    padding: 0.5rem 0.8rem;
    width: fit-content;
    margin: 0;
    transition: all 0.35s ease-in-out;
  }
  .all-container-subtitle-project .view-video:hover {
    opacity: 0.8;
    transform: translateY(-10px) scale(1.05);
  }
} */

/* end of the subtitle style project*/

/* start of the product style */
/* .typeOfProduct {
  padding: 1rem;
}
.typeOfProduct {
  display: flex;
  justify-content: center;
  align-items: center;
}
.typeOfProduct p {
  font-size: 1rem;
  text-transform: capitalize;
} */
/* end of the product style */
/* start of the mentoring program section */

.mentorship-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 2rem;
  row-gap: 2rem;
  justify-content: center;
}
.mentorship-description {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
.mentorship-description h4 {
  text-align: start;
  margin-bottom: 1rem;
  color: #111;
}
.mentorship-description p {
  text-align: justify;
  margin-bottom: 10px;
  color: #111;
}
@media screen and (max-width: 1000px) {
  .mentorship-container {
    display: grid;
    grid-template-columns: 1fr;
    column-gap: 1rem;
    row-gap: 1rem;
    width: 95% !important;
  }
  .mentorship-video {
    position: relative !important;
    padding-top: 56.25% !important;
  }
  .mentorship-video .React-player {
    position: absolute !important;
    top: 0 !important;
    left: 0 !important;
    width: 100% !important;
    height: 220px !important;
    background: transparent !important;
  }
  .mentorship-video .React-player img {
    width: 100% !important;
    height: 100% !important;
  }
}

@media screen and (max-width: 1000px) {
  .container-career div img {
    height: 200px;
  }
  .container-career span {
    margin-top: 0.5rem;
  }
}
/* end of the career path section */
/* start of the objectif section */
.container-objectif div img {
  height: 250px;
}
.container-founder-startup {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin-top: 1.3rem;
}
.container-founder-startup span {
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  /* text-align: center; */
  color: #697589;
  margin-bottom: 0.5rem;
}
.container-founder-startup span:nth-child(1) {
  color: black;
  font-size: 18px;
  color: #fff;
  text-align: left;
}
.container-founder-title span:nth-child(1) {
  color: #697589;
  font-size: 16px;
}
.container-founder-title {
  text-align: start;
  width: 100%;
}
.container-founder-startup a {
  color: var(--primaryColor);
}
/* end of the entrepreneur section */

/* #mentorship {
  background: white;
} */

/* start of the image login */
.visible_container {
  visibility: visible;
}
.unvisible_container {
  visibility: hidden;
}
.container-logo-image-name-icon {
  display: flex;
  width: 130px;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}
.container-logo-image {
  width: 40px;
  height: 40px;
}
.container-logo-image img {
  width: 100%;
  height: 100%;

  border-radius: 100%;
}
.container-logo-image-name-icon span {
  transition: var(--mainTransition);
}
.container-logo-image-name-icon_icon {
  transition: var(--mainTransition);
}

.container-logo-image-name-icon span:hover,
.container-logo-image-name-icon_icon:hover {
  color: var(--primaryColor);
}
.container_information_user_products {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  padding-top: 1rem;
  top: 120px;
  right: 2%;
  transform-origin: 260px 0px;
  border-radius: 4px;
  width: 290px;
  max-width: 95%;
  width: 300px;
  background-color: #f6f9fc;
  box-shadow: 0 0 40px 5px rgb(0 0 0 / 50%);
  z-index: 9010;
}
.container_information_user_products::after,
.user_create_post::after {
  content: "";
  position: absolute;
  border-left: 10px solid transparent;
  border-top: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 10px solid #fff;
  top: -20px;
  right: 20px;
}
.container_information_user_products_row1 {
  margin-top: 1.3rem;
}
.container_information_user_products_row1 span {
  color: #445d6e;
}
.container_information_user_products_row1 span:nth-child(2) {
  color: #02203c;
  font-size: 14px;
  font-weight: 600;
}
.container_information_user_products_row2 {
  color: black;
  color: #445d6e;
  font-size: 14px;
  font-weight: 600;
  margin: 1rem auto !important ;
  text-align: center;
}

.container_information_user_products_row3 div ul li {
  margin-top: 0.5rem;
}

.container_information_user_products_row4 {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.container_information_user_products_row4 div {
  width: 40px;
  height: 40px;
}
.container_information_user_products_row4 div img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}
.container_information_user_products_row4 {
  display: flex;
  width: fit-content;
  justify-content: space-between;
  align-items: center;
  width: 72%;
}
.container_information_user_products_row4 span {
  color: #445d6e;
  margin: 1rem 0;
}
.container_information_user_products_row5 {
  color: #445d6e;
  text-transform: capitalize;
  font-size: 18px;
  cursor: pointer;
}
.container_information_user_products_row6 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: none !important;
}
.container_information_user_products_row6 .svg-logout {
  color: red;
  font-size: 2% !important ;
}
.container_information_user_products .close-btn {
  position: absolute;
  top: 0;
  right: 5%;
  color: var(--primaryColor);
  text-align: end !important;
  display: block;
  font-size: 25px;
  cursor: pointer;
}

@media only screen and (max-width: 900px) {
  .container-logo-image-name-icon {
    width: 100%;
  }
  .container-logo-image {
    width: 40px;
    height: 40px;
    margin-right: 5%;
  }
}
/* end of the iamge login */
.disabled:disabled {
  pointer-events: none !important;
  cursor: default !important;
}

a.disabled {
  position: relative;
  display: inline-block;

  transition: var(--mainTransition);
}

a.disabled[title]:hover::after {
  content: attr(title);
  position: absolute;
  top: 115%;
  font-size: 10px;
  text-transform: justify;
  left: 0;
}
