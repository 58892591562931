/* start of remote workers profile */
.wrapper-container-search-profile {
  width: fit-content;
  margin: 2rem auto;
  display: flex;
  // display: grid;
  // grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
  // grid-row-gap: 1rem !important;
  grid-column-gap: 1rem !important;
  justify-content: center;
  align-items: center;
}
.container-search-major {
  display: inline-block;
  width: 400px !important;
  max-width: 90%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  background: white;
  border-radius: 30px;
  margin: 0 auto;
  position: relative;
}
.container-inner-search-profile {
  width: 90%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.btn-search-profile {
  color: black;
  display: block;
  font-size: 25px;
}
.input-search-hiring {
  font-weight: 700;
  appearance: none !important;
  outline: none !important;
  width: 300px;
  height: 40px;
  border: none;
  font-size: 16px;
}
.container-outer-skills {
  display: flex;
  position: absolute;
  top: 105%;
  bottom: 0;
  left: 0;
  width: 400px;
  max-width: 100%;
  height: 400px;
  margin: 0 auto;
  background: #fff;
  background: rgba(251, 250, 248, 0.95);

  z-index: 500;
  overflow-y: scroll;
  backdrop-filter: blur(12px);
  border-radius: 10px;
  box-shadow: 0 2px 15px 0.3 rgb(0, 0, 0, 0.1);
  transition: var(--mainTransition);
  opacity: 0;
  padding-bottom: 10px;
}
.skills-dropdown-menu {
  pointer-events: auto;
  opacity: 1;
  transition: var(--mainTransition);
  transform: translateY(0);
}
.container-outer-skills ul {
  width: 350px !important;
  height: 350px !important;
  padding: 10px 0 !important;
  text-align: left !important;
  margin: 0 auto !important;
}
.container-outer-skills ul li {
  color: black;
  font-weight: 550 !important;
  cursor: pointer;
}
.container-outer-skills ul li:nth-child(n + 2) {
  margin-top: 10px;
}
.container-filter-by-category-skills {
  width: 1400px;
  margin: 2rem auto !important;
  max-width: 95%;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.btn-primary {
  margin-left: 10px;
  margin: 10px 5px !important;
  background: #fff;
  font-weight: 800;
  border: 1px solid #fff;
  font-size: 14px;
}

@media screen and (max-width: 543px) {
  .wrapper-container-search-profile {
    // display: grid;
    // grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    display: flex;
    flex-direction: column-reverse;
    grid-row-gap: 1rem !important;
    margin: 0 auto;
  }
}
@media screen and (max-width: 391px) {
  .container-search-major {
    width: 360px !important;
  }
}
@media screen and (max-width: 376px) {
  .container-search-major {
    width: 360px !important;
  }
}

@media screen and (max-width: 321px) {
  .container-search-major {
    width: 315px !important;
  }
}
