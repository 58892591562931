.container-share-social {
  display: flex;
  align-items: center;
  max-width: 800px;
  margin: 0 auto;
}
.container-share-social h4 {
  margin-bottom: none !important;
  margin-right: 2rem;
}
.container-overall-rating {
  max-width: 95%;
  width: 800px;
  display: flex;
  align-items: center;
  margin: 0 auto;
}
.container-star-rating {
  display: flex;
  justify-content: center;
  align-items: center;
}
.container-overall-rating h4 {
  margin-right: 2rem;
  text-align: center !important;
}

.container-wrapper-all-reviews {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 2rem 0;
}
.container-inner-all-reviews {
  /* background-color: #fff; */
  width: 800px;
  border: 1px solid white;
  padding: 20px;
  border-radius: 15px;
}

//first row css
.container-img-title-comp {
  display: flex;
  justify-content: space-between;
  // align-items: center;

  margin-bottom: 1rem;
}
.container-img-title-comp_first_row {
  display: flex;
  justify-content: space-between;
  // align-items: center;
  // width: 250px;
}
.container-img-title-comp_first_row_image {
  width: 50px;
  height: 50px;
}
.container-img-title-comp_first_row_image img {
  width: 100%;
  height: 100%;
  border-radius: 100%;
}
//second row css
.container-inner-all-reviews_second_row {
  margin-bottom: 1rem;
}
.container-inner-all-reviews_second_row div h4 {
  text-align: left !important;
}
.container-inner-all-reviews_second_row_career {
  display: flex;
  justify-content: space-between !important;
  align-items: center;
}

//third row
.container-inner-all-reviews_third_row {
  margin-bottom: 1rem;
}
.container-inner-all-reviews_third_row_MGC {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(270px, 1fr));
  grid-template-rows: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  //margin-bottom: 1rem;

  /* grid-template-columns: evenly distributed; */
}

//fourth row
.container-inner-all-reviews_fourth_row {
  margin-bottom: 1rem;
}
.container-inner-all-reviews_fourth_row div h4 {
  text-align: left;
}
.container-inner-all-reviews_fourth_row div p {
  font-size: 15px !important;
}

//fifth row
.container-inner-all-reviews_fifth_row {
  margin-bottom: 1rem;
}
.container-inner-all-reviews_fifth_row div h4 {
  text-align: left;
}
.container-inner-all-reviews_fifth_row div p {
  font-size: 15px !important;
}

//sixth row
.container-inner-all-reviews_sixth_row {
  margin-bottom: 1rem;
}
.container-inner-all-reviews_sixth_row div h4 {
  text-align: left;
}
.container-inner-all-reviews_sixth_row div p {
  font-size: 15px !important;
}

//seventh row
.container-inner-all-reviews_seventh_row {
  margin-bottom: 1rem;
}
.container-inner-all-reviews_seventh_row div h4 {
  text-align: left;
}
.container-inner-all-reviews_seventh_row div p {
  font-size: 15px !important;
}
//eight row
.container-inner-all-reviews_eighth_row_like_share {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 200px;
}
.container-inner-all-reviews_eighth_row_like_share div span:nth-last-child(1) {
  margin-left: 5px;
}
.click-read-more {
  height: fit-content;
}
.click-read-less {
  height: 40px;
  overflow-y: hidden;
  max-height: 40px;
}

@media only screen and (max-width: 1025px) {
  // .container-share-social {
  //   display: flex;
  //   align-items: center;
  //   max-width: 800px;
  //   margin: 0 auto;
  // }
  .container-share-social h4 {
    // margin-bottom: none !important;
    // margin-right: 2rem;
    font-size: 1.2rem !important;
  }
  .container-overall-rating {
    // max-width: 800px;
    // display: flex;
    // align-items: center;
    // margin: 0 auto;
    margin-top: 1rem;
  }
  .container-overall-rating h4 {
    // margin-right: 2rem;
    font-size: 1.2rem !important;
  }

  // .container-wrapper-all-reviews {
  //   display: flex;
  //   justify-content: center;
  //   align-items: center;
  //   margin: 2rem 0;
  // }
  .container-inner-all-reviews {
    /* background-color: #fff; */
    width: 900px;
    max-width: 95%;
    // max-width: 95%;
    border: 2px solid white;
    // padding: 50px;
    // border-radius: 15px;
  }

  //first row css
  .container-img-title-comp {
    display: flex;
    justify-content: space-between;
    // display: grid;
    // grid-template-columns: repeat(auto-fill, minmax(130px, 1fr));
    // align-items: center;

    margin-bottom: 1rem;
  }
  .container-img-title-comp_first_row {
    display: flex;
    justify-content: space-between;
    // align-items: center;
    // width: 250px;
  }
  .container-img-title-comp_first_row_image {
    width: 60px !important;
    height: 60px !important;
  }
  .firstRowTitleDescription h5 {
    font-size: 1.5rem !important;
  }
  .firstRowTitleDescription span {
    font-size: 1.2rem !important;
  }
  .firstRowTitleDescription p {
    font-size: 1.1rem !important;
  }
  // .container-img-title-comp_first_row_image img {
  //   width: 100%;
  //   height: 100%;
  //   border-radius: 100%;
  // }

  //second row css
  // .container-inner-all-reviews_second_row {
  //   margin-bottom: 1rem;
  // }

  .container-inner-all-reviews_second_row div h4 {
    text-align: left !important;
  }
  .container-inner-all-reviews_second_row_career {
    display: flex;
    justify-content: space-between !important;
    align-items: center;
  }
  .container-inner-all-reviews_second_row div h4 {
    font-size: 1.8rem !important;
  }
  .container-inner-all-reviews_second_row_career span {
    font-size: 1.5rem !important;
  }

  //third row
  .thirdRowTitleDescription h5 {
    font-size: 1.8rem !important;
  }
  .thirdRowTitleDescription span {
    font-size: 1.5rem !important;
  }
  .container-inner-all-reviews_third_row {
    margin-bottom: 1rem;
  }
  // .container-inner-all-reviews_third_row_MGC {
  //   display: grid;
  //   grid-template-columns: repeat(auto-fit, minmax(270px, 1fr));
  //   grid-template-rows: 1rem;
  //   display: flex;
  //   justify-content: space-between;
  //   align-items: center;
  //   //margin-bottom: 1rem;

  //   /* grid-template-columns: evenly distributed; */
  // }

  //fourth row
  .container-inner-all-reviews_fourth_row {
    margin-bottom: 1rem;
  }
  .container-inner-all-reviews_fourth_row div h4 {
    // text-align: left;
    font-size: 1.8rem !important;
  }
  .container-inner-all-reviews_fourth_row div p {
    font-size: 1.4rem !important;
  }

  //fifth row
  // .container-inner-all-reviews_fifth_row {
  //   margin-bottom: 1rem;
  // }
  .container-inner-all-reviews_fifth_row div h4 {
    text-align: left;
    font-size: 1.8rem !important;
  }
  .container-inner-all-reviews_fifth_row div p {
    font-size: 1.4rem !important;
  }

  //sixth row
  // .container-inner-all-reviews_sixth_row {
  //   margin-bottom: 1rem;
  // }
  .container-inner-all-reviews_sixth_row div h4 {
    text-align: left;
    font-size: 1.8rem !important;
  }
  .container-inner-all-reviews_sixth_row div p {
    font-size: 1.4rem !important;
  }

  //seventh row
  // .container-inner-all-reviews_seventh_row {
  //   margin-bottom: 1rem;

  // }
  .container-inner-all-reviews_seventh_row div h4 {
    text-align: left;
    font-size: 1.8rem !important;
  }
  .container-inner-all-reviews_seventh_row div p {
    font-size: 1.4rem !important;
  }
}

@media only screen and (max-width: 821px) {
  .container-share-social {
    flex-direction: column;
    margin: 0 auto;
  }
  .container-share-social h4 {
    font-size: 1.2rem !important;
  }
  .container-overall-rating {
    flex-direction: column;
  }
  .descriptionOverallRating {
    font-size: 2.5rem !important;
  }
  .container-overall-rating h4 {
    font-size: 2.5rem !important;
  }

  .container-overall-rating h4 {
    // margin-right: 2rem;
    font-size: 1.2rem !important;
  }

  .container-inner-all-reviews {
    /* background-color: #fff; */
    width: 900px;
    max-width: 95%;
    // max-width: 95%;
    border: 2px solid white;
    // padding: 50px;
    // border-radius: 15px;
  }

  //first row css
  .container-img-title-comp {
    display: flex;
    justify-content: space-between;

    margin-bottom: 1rem;
  }
  .container-img-title-comp_first_row {
    display: flex;
    justify-content: space-between;
    // align-items: center;
    // width: 250px;
  }
  .container-img-title-comp_first_row_image {
    width: 60px !important;
    height: 60px !important;
  }
  .firstRowTitleDescription h5 {
    font-size: 1.3rem !important;
  }
  .firstRowTitleDescription span {
    font-size: 1.2rem !important;
  }
  .firstRowTitleDescription p {
    font-size: 1.1rem !important;
  }

  //second row css
  iner-inner-all-reviews_second_row_career {
    display: flex;
    justify-content: space-between !important;
    align-items: center;
  }
  .container-inner-all-reviews_second_row div h4 {
    font-size: 1.5rem !important;
  }
  .container-inner-all-reviews_second_row_career span {
    font-size: 1.3rem !important;
  }

  //third row
  .thirdRowTitleDescription h5 {
    font-size: 1.5rem !important;
  }
  .thirdRowTitleDescription span {
    font-size: 1.3rem !important;
  }
  //fourth row
  .container-inner-all-reviews_fourth_row {
    margin-bottom: 1rem;
  }
  .container-inner-all-reviews_fourth_row div h4 {
    // text-align: left;
    font-size: 1.5rem !important;
  }
  .container-inner-all-reviews_fourth_row div p {
    font-size: 1.2rem !important;
  }

  //fifth row
  .container-inner-all-reviews_fifth_row div h4 {
    // text-align: left;
    font-size: 1.5rem !important;
  }
  .container-inner-all-reviews_fifth_row div p {
    font-size: 1.2rem !important;
  }

  //sixth row
  .container-inner-all-reviews_sixth_row div h4 {
    // text-align: left;
    font-size: 1.5rem !important;
  }
  .container-inner-all-reviews_sixth_row div p {
    font-size: 1.2rem !important;
  }
  .container-inner-all-reviews_seventh_row div h4 {
    text-align: left;
    font-size: 1.5rem !important;
  }
  .container-inner-all-reviews_seventh_row div p {
    font-size: 1.2rem !important;
  }
}

@media only screen and (max-width: 769px) {
  .container-share-social h4 {
    // margin-bottom: none !important;
    // margin-right: 2rem;
    font-size: 1.4rem !important;
  }
  .container-overall-rating h4 {
    // margin-right: 2rem;
    font-size: 1.2rem !important;
  }

  .container-inner-all-reviews {
    /* background-color: #fff; */
    width: 900px;
    max-width: 95%;
    // max-width: 95%;
    border: 2px solid white;
    // padding: 50px;
    // border-radius: 15px;
  }

  //first row css

  .firstRowTitleDescription h5 {
    font-size: 1.2rem !important;
  }
  .firstRowTitleDescription span {
    font-size: 1.1rem !important;
  }
  .firstRowTitleDescription p {
    font-size: 1.1rem !important;
  }

  //second row css
  .container-inner-all-reviews_second_row div h4 {
    font-size: 1.3rem !important;
  }
  .container-inner-all-reviews_second_row_career span {
    font-size: 1.2rem !important;
  }

  //third row
  .thirdRowTitleDescription h5 {
    font-size: 1.3rem !important;
  }
  .thirdRowTitleDescription span {
    font-size: 1.2rem !important;
  }

  //fourth row
  .container-inner-all-reviews_fourth_row div h4 {
    // text-align: left;
    font-size: 1.3rem !important;
  }
  .container-inner-all-reviews_fourth_row div p {
    font-size: 1.2rem !important;
  }

  //fifth row

  .container-inner-all-reviews_fifth_row div h4 {
    // text-align: left;
    font-size: 1.3rem !important;
  }
  .container-inner-all-reviews_fifth_row div p {
    font-size: 1.2rem !important;
  }
  //sixth row
  .container-inner-all-reviews_sixth_row div h4 {
    // text-align: left;
    font-size: 1.3rem !important;
  }
  .container-inner-all-reviews_sixth_row div p {
    font-size: 1.2rem !important;
  }

  //seventh row
  .container-inner-all-reviews_seventh_row div h4 {
    // text-align: left;
    font-size: 1.3rem !important;
  }
  .container-inner-all-reviews_seventh_row div p {
    font-size: 1.2rem !important;
  }
}

@media only screen and (max-width: 541px) {
  .container-share-social h4 {
    font-size: 1.4rem !important;
  }
  .container-overall-rating h4 {
    // margin-right: 2rem;
    font-size: 1.2rem !important;
  }
  //first row css
  .container-img-title-comp {
    display: flex;
    justify-content: space-between;
    margin-bottom: 1rem;
  }

  .firstRowTitleDescription h5 {
    font-size: 0.8rem !important;
  }
  .firstRowTitleDescription span {
    font-size: 0.7rem !important;
  }
  .firstRowTitleDescription p {
    font-size: 0.7rem !important;
  }

  .container-inner-all-reviews_second_row_career {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(100%, 1fr));
  }

  //third row
  .thirdRowTitleDescription h5 {
    font-size: 1.2rem !important;
  }
  .thirdRowTitleDescription span {
    font-size: 1rem !important;
  }
  .container-inner-all-reviews_third_row {
    margin-bottom: 1rem;
  }

  //fourth row

  .container-inner-all-reviews_fourth_row div h4 {
    // text-align: left;
    font-size: 1.2rem !important;
  }
  .container-inner-all-reviews_fourth_row div p {
    font-size: 1rem !important;
  }

  //fifth row
  .container-inner-all-reviews_fifth_row div h4 {
    // text-align: left;
    font-size: 1.2rem !important;
  }
  .container-inner-all-reviews_fifth_row div p {
    font-size: 1rem !important;
  }
  //sixth row
  .container-inner-all-reviews_sixth_row div h4 {
    // text-align: left;
    font-size: 1.2rem !important;
  }
  .container-inner-all-reviews_sixth_row div p {
    font-size: 1rem !important;
  }

  .container-inner-all-reviews_seventh_row div h4 {
    // text-align: left;
    font-size: 1.2rem !important;
  }
  .container-inner-all-reviews_seventh_row div p {
    font-size: 1rem !important;
  }
}

@media only screen and (max-width: 415px) {
  .container-share-social h4 {
    // margin-bottom: none !important;
    // margin-right: 2rem;
    font-size: 1.4rem !important;
  }
  .container-overall-rating h4 {
    // margin-right: 2rem;
    font-size: 1.2rem !important;
  }

  //first row css
  .container-img-title-comp {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(45%, 1fr));
    grid-template-rows: 6rem !important;
    // align-items: center;

    // margin-bottom: 1rem;
  }

  .firstRowTitleDescription h5 {
    font-size: 1rem !important;
  }
  .firstRowTitleDescription span {
    font-size: 0.8rem !important;
  }
  .firstRowTitleDescription p {
    font-size: 0.8rem !important;
  }

  //second row css
  .container-inner-all-reviews_second_row_career {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(100%, 1fr));
  }

  //third row
  .thirdRowTitleDescription h5 {
    font-size: 0.9rem !important;
  }
  .thirdRowTitleDescription span {
    font-size: 0.8rem !important;
  }

  //fourth row

  .container-inner-all-reviews_fourth_row div h4 {
    // text-align: left;
    font-size: 1.2rem !important;
  }
  .container-inner-all-reviews_fourth_row div p {
    font-size: 1rem !important;
  }

  //fifth row

  .container-inner-all-reviews_fifth_row div h4 {
    // text-align: left;
    font-size: 1.2rem !important;
  }
  .container-inner-all-reviews_fifth_row div p {
    font-size: 1rem !important;
  }
  //sixth row

  .container-inner-all-reviews_sixth_row div h4 {
    // text-align: left;
    font-size: 1.2rem !important;
  }
  .container-inner-all-reviews_sixth_row div p {
    font-size: 1rem !important;
  }

  //seventh row
  .container-inner-all-reviews_seventh_row div h4 {
    // text-align: left;
    font-size: 1.2rem !important;
  }
  .container-inner-all-reviews_seventh_row div p {
    font-size: 1rem !important;
  }
}

@media only screen and (max-width: 345px) {
  .container-share-social h4 {
    font-size: 1.4rem !important;
  }

  .container-overall-rating h4 {
    // margin-right: 2rem;
    font-size: 1.2rem !important;
  }

  //first row css
  .container-img-title-comp {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(45%, 1fr));
    grid-template-rows: 6rem !important;
  }

  .firstRowTitleDescription h5 {
    font-size: 0.7rem !important;
  }
  .firstRowTitleDescription span {
    font-size: 0.6rem !important;
  }
  .firstRowTitleDescription p {
    font-size: 0.6rem !important;
  }

  //third row
  .container-inner-all-reviews_third_row_MGC {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(45%, 1fr));
    grid-template-rows: 6rem !important;
  }
  .thirdRowTitleDescription h5 {
    font-size: 0.9rem !important;
  }
  .thirdRowTitleDescription span {
    font-size: 0.9rem !important;
  }

  //fourth row

  .container-inner-all-reviews_fourth_row div h4 {
    // text-align: left;
    font-size: 1.2rem !important;
  }
  .container-inner-all-reviews_fourth_row div p {
    font-size: 1rem !important;
  }
  //fifth row

  .container-inner-all-reviews_fifth_row div h4 {
    // text-align: left;
    font-size: 1.2rem !important;
  }
  .container-inner-all-reviews_fifth_row div p {
    font-size: 1rem !important;
  }

  //sixth row
  .container-inner-all-reviews_sixth_row div h4 {
    // text-align: left;
    font-size: 1.2rem !important;
  }
  .container-inner-all-reviews_sixth_row div p {
    font-size: 1rem !important;
  }

  //seventh row
  .container-inner-all-reviews_seventh_row div h4 {
    // text-align: left;
    font-size: 1.2rem !important;
  }
  .container-inner-all-reviews_seventh_row div p {
    font-size: 1rem !important;
  }
}
