/* start of the footer style */
h4 {
  margin-bottom: 0.5rem !important;
  font-family: Barlow, sans-serif !important;
  font-weight: 600 !important;
  line-height: 1.2 !important;
  //color: #fff !important;
  font-weight: 800 !important;
  font-size: 20px !important;
}
.section {
  text-align: center;
}
.section p {
  margin: 1rem 0;
  line-height: 1.6;
  font-family: "Qanelas Soft Light ";
  font-style: normal;
  font-weight: 400;
  font-size: 17px;
}
h4 {
  text-align: left;
  text-transform: capitalize;
}
.container-footer div ul li a {
  text-decoration: none;
  //   font-size: 17px !important;
  margin-top: 1rem;
  font-weight: 400 !important;
  display: block;
  color: #fff;
}
// .footer-product-links p a {
//   font-size: 17px !important;
//   font-weight: 400 !important;
// }
.footer {
  width: 100vw;
  min-height: 100vh;
  background: rgba(0, 0, 0, 1);
  padding: 2rem 0;
}
.container-new-letter {
  //   width: 100vw;
  margin: 2rem 0;
}
.container-new-letter p:nth-child(1) {
  font-size: 1.5 rem !important;
  color: #fff;
}
.container-new-letter p:nth-child(2) {
  font-size: 1.1rem;
  color: #fff;
}
.new-letters {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
}
.new-letters input {
  width: 30%;
  height: 60px;
  border: none;
  //   padding-left: 5%;
  outline: none;
}
.new-letters input::placeholder {
  text-align: 6%;
}
.new-letters button {
  //width: 5%;
  height: 60px;
  cursor: pointer;
  background: #fff;
  color: black;
  border: none;
  font-weight: bold;
  margin-left: 0.2%;
  transition: var(--mainTransition);
}
.new-letters button:hover {
  background: var(--primaryColor);
  // background: #059862;
  color: white;
}
.container-new-letter .successReview {
  margin-bottom: 1rem;
  font-size: 1.2rem !important;
}
.container-new-letter .errorReview {
  margin-top: 1rem;
  font-size: 1.2rem !important;
}

@media screen and (max-width: 915px) {
  .new-letters {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
  }
  .new-letters input {
    width: 75%;
    border: none;
    outline: none;
  }
  .new-letters button {
    width: 20%;
    font-size: 14px;
  }
  .new-letters input::placeholder {
    text-align: 0;
    font-size: 14px;
  }
}

.footer-product-links p a {
  color: #fff;
  /* font-size: 0.9rem; */
  text-decoration: none;
  transition: var(--mainTransition);
}
.footer-product-links p a:hover {
  color: var(--primaryColor);
  color: #059862;
}

.container-footer {
  display: grid;
  grid-template-columns: 1fr;
  width: 90%;
  text-align: left;
  margin: 0 auto;
}
.container-footer div h4 {
  color: var(--primaryColor);
  color: #059862;
  text-align: left;
  margin: 1em 0;
  font-size: 1.2rem;
  text-transform: capitalize;
}
/* .container-footer div ul {

  } */
.container-footer div ul li {
  list-style: none;
  line-height: 1.7;
  font-weight: 600;
}
.container-footer div ul li a {
  text-decoration: none;
  font-size: 15px !important;
  color: #fff;
  font-family: Roboto, sans-serif !important;
  text-transform: capitalize;
}
address {
  display: block;
  font-size: 14px;
  margin-top: 1rem !important;
  color: #fff;
}
.office-address {
  font-size: 17px;
  color: #fff;
  font-weight: 800;
}
.email-address {
  font-size: 17px;
  font-weight: italic;
  color: white;
  text-transform: none;
}

footer {
  position: relative;
}
.copyright {
  position: absolute;
  bottom: 0;
  color: #fff;
  font-size: 13px !important;
  text-align: center;
  left: 50%;
  transform: translateX(-50%);
  letter-spacing: var(--mainSpacing);
  /* width: 100vw; */
}
.copyright span {
  color: var(--primaryColor) !important;
  color: #059862;
}

@media screen and (min-width: 700px) {
  .container-footer {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(fit-content, 1fr));
    margin: 0 auto;
    // width: 90vw;
  }
  .container-footer div h4 {
    font-size: 1.2rem;
  }
  .container-footer div ul li a {
    font-size: 17px;
  }
  //   .footer-product-links p a {
  //     font-size: 17px;
  //     font-weight: 600;
  //   }
}
@media screen and (min-width: 1000px) {
  .container-footer {
    display: flex;
    justify-content: space-between;
    margin: 0 auto;
    width: 90vw;
  }
}
@media screen and (min-width: 1920px) {
  .container-footer {
    width: 70vw;
  }
}

/* end of the footer style */
