.loading {
  text-transform: capitalize;
  text-align: center;
  margin-top: 1rem;
}
.loading img {
  width: 200px;
  height: 200px;
  color: #059862 !important;
}
