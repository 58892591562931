.container-watch-video {
  position: relative;
  margin: 2rem auto;
  border-radius: var(--radius);
  border: 3px solid var(--primaryColor);
}
.container-watch-video,
.container-watch-video-background-inner {
  display: flex;
  justify-content: center;
  width: 200px;
  height: 100%;
  align-items: center;
  background-color: transparent;
  cursor: pointer;
  padding: 8px 0;
}
.container-watch-video-background-outer:hover {
  width: 100%;
  background: var(--primaryColor);
  opacity: 1;
}

.container-watch-video-background-inner {
  opacity: 0;
  transition: width 0.4s cubic-bezier(0.34, 0.63, 0.29, 0.96);
}
.container-watch-video-background-inner:hover {
  opacity: 1;
}
.container-watch-video-background-inner .service-icon {
  background: none;
  color: black !important;
}
.container-watch-video-background-inner .service-icon,
.container-watch-video .service-icon {
  margin-bottom: 0 !important;
  padding: 0 !important;
}

.container-watch-video-background-outer {
  transition: width 0.4s cubic-bezier(0.34, 0.63, 0.29, 0.96);
  left: 0;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  width: 0%;
  height: 100%;
  opacity: 0;
}

.img-window {
  /* visibility: hidden; */
  position: fixed;
  min-width: 100vw;
  min-height: 100vh;
  top: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
  transition: var(--mainTransition);
}

.btn-danger,
.authentification-btn-close,
.btn-danger-product,
.btn-slide-right {
  position: absolute;
  top: 0%;
  right: 5%;
  cursor: pointer;
  font-weight: 400;
  font-size: 2.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--primaryColor);
  opacity: 0.5;
}

.showVideo,
.showPopUp {
  visibility: visible;
  transition: var(--mainTransition);
}
.img-window .btn-danger:hover {
  opacity: 1;
}
.container-video {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 1000px;
  height: fit-content;
  margin: 0 auto;
  overflow-x: hidden;
  background: transparent;
  border-radius: 4px;
  z-index: 99999;
  position: relative;
  padding-top: 56.25%;
  background: red;
}
.container-video .Youtube-player {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.container-video .Youtube-player iframe {
  width: 100%;
  height: 100%;
}
.container-video-ceo-show {
  transform: scale(1);
  /* transition: var(--mainTransition); */
}
.container-video-ceo-hide {
  transform: scale(0);
  /* transition: var(--mainTransition); */
}

@media screen and (max-width: 768px) {
  .container-video {
    width: 390px !important;
    height: 225px !important;
  }
}
@media screen and (max-width: 378px) {
  .container-video {
    width: 350px !important;
  }
}
@media screen and (max-width: 320px) {
  .container-video {
    width: 300px !important;
  }
}

/* start of the goal style */
