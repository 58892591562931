.full-window {
  visibility: hidden;
  position: fixed;
  min-width: 100vw;
  min-height: 100vh;
  top: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.75);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2000;
  transition: var(--mainTransition);
}
// .full-window-position {
//   position: fixed;
//   min-width: 100vw;
//   min-height: 100vh;
//   display: flex;
//   justify-content: center;
//   align-items: center;
// }
// .full-window-display {
//   display: flex;
//   justify-content: center;
//   align-items: center;
// }
.spinner-show {
  transform: scale(1);
}
.spinner-hide {
  transform: scale(0);
}

.showPopUp {
  visibility: visible;
  transition: var(--mainTransition);
}
.closePopUp {
  visibility: hidden;
  transition: var(--mainTransition);
}
.showModelSubmit {
  visibility: visible;
  transition: var(--mainTransition);
  // transform: scale(1);
}
.hideModelSubmit {
  display: none;
  visibility: hidden;
  transition: var(--mainTransition);
  transform: scale(0);
}
// .full-window {
//   opacity: 1;
// }
.error {
  color: red;
  display: block;
  text-align: center;
  margin: 0 auto;
}
.container-form-major-review {
  width: 600px;
  max-width: 100%;
  margin: 0 auto;
  border: 2px solid var(--primaryColor);
  padding: 15px 50px;
}

.container-dark-mode {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 200px;
}
.container-form-major-review h6 {
  margin-bottom: 2rem;
}
.form-group-container {
  margin-bottom: 1rem;
}
.form-group-container label {
  font-size: 20px;
  font-weight: 800;
}
.form-group-container p.subtext {
  font-size: 15px;
  color: #fff;
  margin: 0.5rem 0;
}
.form-group {
  width: 30rem;
  height: 35px;
  max-width: 98%;
  border-radius: 0.25rem;
  position: relative;
}
.form-control {
  display: block;
  height: 35px;
  height: 100%;
  width: 100%;
  outline: none;
  border-radius: 0.25rem;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
  border: 1px solid #ced4da;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1);
  text-overflow: ellipsis;
  white-space: nowrap;
  text-indent: 10px;
  outline: none;
}
.form-control-background-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right 0.75rem center;
  background-size: 20px;
  appearance: none;
}
.icon-open-close {
  position: absolute;
  top: 50%;
  right: 22px;
  font-size: 2.8rem; /* Adjust this value to increase or decrease the size */
  color: #111;
  width: 2.8rem; /* You can also adjust the width if needed */
  height: auto; /* This allows the height to scale with the width */
  display: inline-block;
  cursor: pointer;
}

.form-group-list {
  position: absolute;
  top: 35px;
  right: 0%;
  background: #fff;
  width: 30rem;
  max-width: 98%;
  height: 200px;
  overflow-y: scroll;
  z-index: 2000;
}
.form-group-list ul {
  margin: 5px 0;
  margin-left: 15px;
}
.form-group-list ul option,
.form-group-list ul li {
  color: #111;
  font-size: 15px;
  display: block;
  margin-top: 4px;
  cursor: pointer;
}
.show {
  visibility: visible;
  // transition: transform 0.3s ease;
}
.hide {
  visibility: hidden;
  // transition: transform 0.3s ease;
}
.rotated-in-90deg {
  transform: translate(50%, -50%) rotate(90deg);
  // transition: transform 0.3s ease;
}
.rotated-in-zerodeg {
  transform: translate(50%, -50%) rotate(0deg);
  // transition: transform 0.3s ease;
}
.form-group-container-textarea {
  width: 30rem;
  height: 13rem;
  max-width: 98%;
  margin: 1rem 0;
}
.form-group-container-textarea textarea {
  font-size: 16px !important;
  width: 100%;
  height: 100%;
  max-width: 98%;
  padding-left: 5%;
  outline: none;
  resize: none;
  border-radius: 4px;
  cursor: pointer;
  text-indent: 10px;
}
