.section {
  margin: 2rem 0;
  width: 100%;
}
.all-ceo-description-about {
  display: flex;
  justify-content: space-between;
  width: 90%;
  margin: 1rem auto 2rem;
}
.ceo-container-description-about {
  text-align: center;
  width: 100%;
}
.ceo-container-description-about h2 {
  color: #02203c;
  font-family: Ubuntu, Helvetica, Arial, sans-serif;
  line-height: 1.3;
  font-size: 35px;
  font-style: italic;
}
.ceo-container-description-about h4 {
  font-weight: 800 !important;

  color: #fff !important;
  text-align: center;
  line-height: 2.5;
}
.ceo-picture-sociaux-about {
}
.ceo-container-about {
  width: 500px;
  height: 500px;
  margin: 1.5rem auto;
}
.ceo-container-about img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  max-zoom: initial;
  border-radius: 100%;
  box-shadow: 0 10px 30px 1px rgba(0, 0, 0, 0.3);
}
.container-sociaux-links-ceo-about {
  max-width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.ceo-background-about {
  width: 50%;
  max-width: 95%;
}
.ceo-background-about p {
  color: #d1dbe6;
  line-height: 1.8;
  font-size: 20px;
  font-weight: 400;
  text-align: left;
}
/* start media query */
@media only screen and (min-width: 1920px) {
}
@media only screen and (max-width: 1536px) {
}

@media only screen and (max-width: 1281px) {
  .all-ceo-description-about {
    width: 95%;
  }

  .ceo-container-description-about h2 {
    line-height: 1.5;
    font-size: 30px;
  }
  .ceo-container-description-about h4 {
    font-weight: 400 !important;
    text-align: center;
  }

  .ceo-container-about {
    width: 450px;
    height: 500px;
  }
  .ceo-background-about p {
    font-size: 25px;
  }
}

@media only screen and (max-width: 1025px) {
  .ceo-background-about p {
    font-size: 25px;
  }
  .container-align-links-team-about a {
    font-size: 15px;
  }
}
@media only screen and (max-width: 913px) {
  .all-ceo-description-about {
    display: grid;
    width: 100%;
    margin: 2rem auto;
  }
  .ceo-container-about {
    width: 800px;
    height: 800px;
  }
  .ceo-background-about {
    width: 95%;
    max-width: 100%;
    margin: 2rem auto 0;
  }
  .ceo-background-about p {
    font-size: 35px;
  }
  .ceo-container-description-about {
    text-align: center;
    width: 100%;
  }
  .container-align-links-team-about a {
    font-size: 25px;
  }
}

@media only screen and (max-width: 821px) {
}

@media only screen and (max-width: 769px) {
  .ceo-container-about {
    width: 700px;
    height: 700px;
  }
}

@media only screen and (max-width: 541px) {
  .ceo-container-about {
    width: 450px;
    height: 450px;
  }
  .ceo-background-about p {
    font-size: 22px;
  }
  .ceo-container-description-about {
    text-align: center;
    width: 100%;
  }
  .container-align-links-team-about a {
    font-size: 15px;
  }
}
@media only screen and (max-width: 415px) {
  .ceo-container-about {
    width: 400px;
    height: 400px;
  }
  .ceo-background-about p {
    font-size: 25px;
  }
  .ceo-container-description-about {
    text-align: center;
    width: 100%;
  }
  .container-align-links-team-about .btn-primary {
    font-size: 9px;
  }
}

@media only screen and (max-width: 394px) {
  .ceo-container-about {
    width: 375px;
    height: 375px;
  }
  .ceo-background-about p {
    font-size: 20px;
  }
  .ceo-container-description-about {
    text-align: center;
    width: 100%;
  }
  .container-align-links-team-about a {
    font-size: 9px;
  }
}

@media only screen and (max-width: 376px) {
  .ceo-container-about {
    width: 350px;
    height: 350px;
  }
}

@media only screen and (max-width: 361px) {
  .ceo-container-about {
    width: 340px;
    height: 340px;
  }
  .container-align-links-team-about .btn-primary {
    font-size: 8px;
  }
}
@media only screen and (max-width: 281px) {
  .ceo-container-about {
    width: 230px;
    height: 230px;
    margin: 0 auto;
    text-align: center;
  }
  .ceo-container-description-about {
    max-width: 90% !important;
  }

  .ceo-container-description-about h2 {
    /* color: #89c6ff; */
    font-family: Ubuntu, Helvetica, Arial, sans-serif;
    line-height: 2.5 !important;
    font-size: 15px;
    font-style: italic;
  }
  .ceo-container-description-about h4 {
    line-height: 1.2;
    margin: 0 !important;
    padding: 0 !important;
    font-size: 12px !important;
    max-width: 90%;
  }
  .ceo-container-description-about p {
    font-size: 15px;
  }
  .container-sociaux-links-ceo-about {
    max-width: 95%;
    text-align: center !important;
  }
  .ceo-picture-description-about {
    max-width: 90%;

    padding: 0 !important;
  }
  .ceo-background-about {
    width: 95%;
    margin: 0 auto;
  }
  .ceo-background-about p {
    font-size: 15px;
  }
  .container-align-links-team-about {
    width: 60%;
  }
  .container-align-links-team-about .btn-primary {
    font-size: 5px;
    width: fit-content !important;
  }
}
/* end of media query*/
